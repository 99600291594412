<template>
  <component :is="roleData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="roleData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching role data
      </h4>
      <div class="alert-body">
        No role found with this role id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-roles-list'}"
        >
          Role List
        </b-link>
        for other roles.
      </div>
    </b-alert>
    <div v-if="roleData">
      <div class="mt-2 pt-75">
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Role Info: Input Fields -->
          <b-form @reset.prevent="resetForm">
            <b-row>
              <!-- Field: Full Name -->
              <b-col
                cols="12"
                lg="4"
                md="12"
                sm="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="Название"
                  rules="required"
                >
                  <b-form-group
                    label="Название"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="roleData.name"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- Field: Email -->
              <b-col
                cols="12"
                lg="8"
                md="12"
                sm="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="Описание"
                  rules="required"
                >
                  <b-form-group
                    label="Описание"
                    label-for="description"
                  >
                    <b-form-input
                      id="description"
                      v-model="roleData.description"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- PERMISSION TABLE -->
            <b-form-group
              label="Доступ"
              label-for="roles"
            >
              <b-table
                striped
                responsive
                class="mb-0"
                :items="permissionsData"
              >
                <template #cell(module)="data">
                  {{ data.value }}
                </template>
                <template #cell()="data">
                  <b-form-checkbox
                    v-model="permissionsData[data.index][data.field.key]"
                    :checked="data.value"
                    @input="permissionCreater"
                  />
                </template>
              </b-table>
            </b-form-group>
            <!-- Action Buttons -->
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="handleSubmit(save)"
            >
              Сохранить
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :to="{name: 'apps-roles-list'}"
            >
              Отмена
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </div>
    <div
      v-else
      class="text-center text-primary my-2"
    >
      <b-spinner
        class="align-middle mr-1"
      />
      <strong>Загрузка...</strong>
    </div>
  </component>
</template>

<script>
import {
  BFormInvalidFeedback, BCard, BAlert, BLink, BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCardHeader, BCardTitle, BFormCheckbox, BSpinner,
} from 'bootstrap-vue'
import {
  reactive,
  onUnmounted,
  ref,
} from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import router from '@/router'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import navMenuItems from '@/navigation/vertical'
import store from '@/store'
import roleStoreModule from '../roleStoreModule'

export default {
  components: {
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BSpinner,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    // Use toast
    const toast = useToast()

    const roleData = ref({
      name: null,
      description: null,
      permissions: null,
      updatedAt: null,
    })

    const returnModule = item => {
      const obj = {
        module: item.resource,
        read: false,
        write: false,
        create: false,
        delete: false,
      }
      return obj
    }
    const moduleItems = navMenuItems.filter(item => item.resource).map(returnModule)
    const permissionsData = reactive(moduleItems)

    const ROLE_APP_STORE_MODULE_NAME = 'app-role'

    // Register module
    if (!store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.registerModule(ROLE_APP_STORE_MODULE_NAME, roleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.unregisterModule(ROLE_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-role/fetchRole', {
      _where: `(id,eq,${router.currentRoute.params.id})`,
      _fields: '-',
      _tableconstruction_version: 'vertri',
      _zeTable: 'Groups',
    })
      .then(response => {
        const { records } = response.data
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < records.length; i++) {
          if (Object.hasOwnProperty.call(records[i], 'permissions')) {
            records[i].permissions = JSON.parse(records[i].permissions)
          }
        }
        const obj = records[0]
        // ['name', 'description', 'updatedAt', 'permissions'].forEach
        Object.keys(roleData.value).forEach(key => {
          roleData.value[key] = obj[key]
        })
        return obj
      }).then(obj => {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < permissionsData.length; i++) {
          const subject = permissionsData[i].module
          // eslint-disable-next-line no-plusplus
          for (let j = 0; j < obj.permissions.length; j++) {
            const element = obj.permissions[j]
            if (element.subject === subject) {
              permissionsData[i][element.action] = true
            } else if (element.subject === 'all' && element.action === 'manage') {
              ['read', 'write', 'create', 'delete'].forEach(action => {
                permissionsData[i][action] = true
              })
            }
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
    const permissionCreater = () => {
      console.log(permissionsData)
      const permiss = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < permissionsData.length; i++) {
        if (permissionsData[i].read === true) {
          permiss.push({ action: 'read', subject: permissionsData[i].module })
        }
        if (permissionsData[i].write === true) {
          permiss.push({ action: 'write', subject: permissionsData[i].module })
        }
        if (permissionsData[i].create === true) {
          permiss.push({ action: 'create', subject: permissionsData[i].module })
        }
        if (permissionsData[i].delete === true) {
          permiss.push({ action: 'delete', subject: permissionsData[i].module })
        }
      }
      roleData.value.permissions = JSON.stringify(permiss)
      console.log(permiss, roleData)
      console.log('roleData', roleData)
    }
    const save = () => {
      // console.log(permissionsData)
      // roleData.value.createdAt = new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 19).replace('T', ' ')
      roleData.value.updatedAt = new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 19).replace('T', ' ')
      // console.log((roleData))
      // newRoleData.name = roleData.name
      // newRoleData.description = roleData.description
      // newRoleData.updatedAt = new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 19).replace('T', ' ')
      // newRoleData.permissions = JSON.stringify(permiss)

      store.dispatch('app-role/editRole', { id: router.currentRoute.params.id, roleData: roleData.value })
        .then(response => {
          console.log(response)
          router.replace({ name: 'apps-roles-list' })
            .then(() => {
              toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Успешно',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'Вы успешно обновили роль доступа.',
                },
              })
            })
        })
        .catch(error => {
          // console.log(error)
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ошибка',
              icon: 'XCircleIcon',
              variant: 'error',
              text: error.message,
            },
          })
        })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(roleData.value)
    return {
      roleData,
      save,
      permissionsData,
      permissionCreater,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style>

</style>
