import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Groups/', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRole(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Groups/', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRole(ctx, roleData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/insertRow/Groups/', roleData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editRole(ctx, { id, roleData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/updateRow/Groups/${id}`, roleData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
